var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { dataLayer } from "Static/variables";
export var addImpressionsGTM = function (productsList, listInfo) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            currencyCode: "RUB",
            impressions: productsList.map(function (product, idx) { return ({
                brand: product.brand.name,
                category: product.category.name,
                id: product.id,
                list: listInfo,
                name: "".concat(product.model).concat(product.model_full ? " " + product.model_full : ""),
                position: idx + 1,
                price: product.actual_price,
                variant: product.sku,
            }); }),
        },
    });
};
export var addToCartGTM = function (product, actionFieldList, quantity) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            currencyCode: "RUB",
            add: {
                actionField: { list: actionFieldList },
                products: [
                    {
                        brand: product.brand.name,
                        category: product.category.name,
                        id: product.id,
                        name: product.model + (product.model_full ? " ".concat(product.model_full) : ""),
                        price: product.actual_price,
                        quantity: quantity,
                        variant: product.sku,
                    },
                ],
            },
        },
    });
};
export var removeFromCartGTM = function (productsList, quantity) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "gtm-ee-event",
        ecommerce: {
            remove: {
                products: productsList.map(function (product) { return ({
                    name: "".concat(product.model).concat(product.model_full ? " " + product.model_full : ""),
                    id: product.id,
                    price: product.actual_price,
                    brand: product.brand.name,
                    category: product.category.name,
                    variant: product.sku,
                    quantity: quantity,
                }); }),
            },
        },
    });
};
export var addToWishlistGTM = function (product, category) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "add_to_wishlist",
        currency: "RUB",
        value: product.actual_price,
        ecommerce: {
            items: [
                __assign(__assign({ item_id: product.sku, item_name: product.model + (product.model_full ? " ".concat(product.model_full) : ""), item_brand: product.brand.name, item_category: product.category.name }, (category && { item_list_name: "Catalog page - ".concat(category) })), { item_variant: product.barcode, price: product.actual_price, quantity: 1 }),
            ],
        },
    });
};
