var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./ClientWidget.scss";
import React, { useState } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { getRoistatVisitId } from "Utils/getRoistatVisitId";
import { WA_number } from "Static/variables";
import { ViberSvg } from "./svg/ViberSvg";
import { MessageSvg } from "./svg/MessageSvg";
import { ChatSvg } from "./svg/ChatSvg";
import { CustomDrawer } from "Components/CustomDrawer/CustomDrawer";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { IconPopover } from "./components/IconPopover";
export var ClientWidget = function () {
    var _a = useAppSelector(function (state) { return state.app; }), deviceInfo = _a.deviceInfo, clientWidgetSubClass = _a.clientWidgetSubClass;
    var _b = __read(useState(false), 2), isWidgetOpened = _b[0], setIsWidgetOpened = _b[1];
    var openWidget = function () {
        deviceInfo.deviceType === "desktop" && setIsWidgetOpened(true);
    };
    var closeWidget = function () {
        setIsWidgetOpened(false);
    };
    var popoverClickHandler = function () {
        deviceInfo.deviceType === "mobile" ? openDrawer() : openWidget();
    };
    var openMessanger = function (type) {
        var msg = "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043E\u0442\u043F\u0440\u0430\u0432\u044C\u0442\u0435 \u044D\u0442\u043E \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \u0438 \u0434\u043E\u0436\u0434\u0438\u0442\u0435\u0441\u044C \u043E\u0442\u0432\u0435\u0442\u0430. \u0412\u0430\u0448 \u043D\u043E\u043C\u0435\u0440: ".concat(getRoistatVisitId() || "404");
        window.open(type === "telegram"
            ? "https://t.me/lsnet_content"
            : type === "viber"
                ? "viber://pa?chatURI=lsnetru&text=".concat(msg)
                : "https://wa.me/".concat(WA_number, "?text=").concat(msg));
        deviceInfo.deviceType === "mobile" && closeDrawer();
    };
    var _c = __read(useState(false), 2), isDrawerShown = _c[0], setIsDrawerShown = _c[1];
    var openDrawer = function () {
        setIsDrawerShown(true);
    };
    var closeDrawer = function () {
        setIsDrawerShown(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "clientWidget" +
                (isWidgetOpened ? " clientWidget_opened" : "") +
                (clientWidgetSubClass ? " ".concat(clientWidgetSubClass) : ""), onClick: popoverClickHandler, onMouseEnter: openWidget, onMouseLeave: closeWidget }, isWidgetOpened ? (React.createElement(React.Fragment, null,
            React.createElement(IconPopover, { messangerName: 'Viber' },
                React.createElement(ViberSvg, { className: 'clientWidget__icon', onClick: function () { return openMessanger("viber"); } })),
            React.createElement(IconPopover, { messangerName: '\u043C\u0435\u0441\u0441\u0435\u043D\u0434\u0436\u0435\u0440' },
                React.createElement(ChatSvg, { className: 'clientWidget__icon', onClick: function () { return openMessanger("whatsapp"); } })))) : (React.createElement(MessageSvg, { className: 'clientWidget__icon' }))),
        React.createElement(CustomDrawer, { rootClassName: 'clientWidget__drawer customServiceDrawer_bottom', placement: 'bottom', open: isDrawerShown, destroyOnClose: true, onClose: closeDrawer },
            React.createElement("div", { className: 'customServiceDrawer_bottom__title' },
                React.createElement("span", { className: 'typography_tec_15_menu' }, "\u0417\u0430\u0434\u0430\u0442\u044C \u0432\u043E\u043F\u0440\u043E\u0441"),
                React.createElement(CloseIcon, { className: 'customServiceDrawer_bottom__title__closeIcon', size: 'small', withBackground: true, onClick: closeDrawer })),
            React.createElement("div", { className: 'clientWidget__drawer__content customServiceDrawer_bottom__content', style: {
                    maxHeight: deviceInfo.deviceType === "mobile"
                        ? document.documentElement.clientHeight - 112 + "px"
                        : "calc(100vh - 112px)",
                } },
                React.createElement("button", { className: 'clientWidget__drawer__content__control customBtn customBtn_withPrefix customBtn_outline', onClick: function () { return openMessanger("viber"); } },
                    React.createElement(ViberSvg, { className: 'clientWidget__drawer__content__control__icon' }),
                    React.createElement("span", null, "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 Viber")),
                React.createElement("button", { className: 'clientWidget__drawer__content__control customBtn customBtn_withPrefix customBtn_outline', onClick: function () { return openMessanger("whatsapp"); } },
                    React.createElement(ChatSvg, { className: 'clientWidget__drawer__content__control__icon' }),
                    React.createElement("span", null, "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 \u043C\u0435\u0441\u0441\u0435\u043D\u0434\u0436\u0435\u0440")),
                React.createElement("button", { className: 'clientWidget__drawer__content__control customBtn customBtn_containedGrey', onClick: closeDrawer }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
};
